<template>
  <div>
    <ExpertTitle />
    <section class="panel">
      <div class="panel-content">
        <router-view />
      </div>
    </section>
  </div>
</template>

<script>

export default {
  name: 'Expert',
  components: {
    ExpertTitle: () => import('./components/expertTitle.vue')
  },
};
</script>

<style lang="less" scoped>
.panel{
  height: calc(100vh - 152px);
  background: white;
  margin-top: 16px;
  &-content{
    height: 100%;
    overflow: scroll;
  }
}
</style>
